import PropTypes from "prop-types"
import React from "react"
import "./header.css"

import Nav from "./nav"

const Header = ({ siteTitle }) => (
  <header>
    <div className="border-bottom-wrapper">
      <div className="border-bottom">
        <h1>ROXIE & GENEVIÈVE</h1>
        <span className="subtitle">
          Friday May 20<sup>th</sup>, 2022
        </span>
      </div>
    </div>
    <Nav />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
