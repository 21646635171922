import React from "react"
import { Link } from "gatsby"
import "./nav.css"

export default class Nav extends React.Component {
  state = {
    isActiveSet: false,
    isHamburgerToggled: true,
    isFirstToggle: true,
  }

  isBrowser = () => typeof window !== "undefined"
  isMobile = () => {
    if (this.isBrowser()) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        if (this.state.isFirstToggle) {
          this.setState({ isFirstToggle: false, isHamburgerToggled: false })
        }
        return true
      } else {
        return false
      }
    }
    return false
  }

  stampTexture = () => {
    return `active-${(Math.floor(Math.random() * 5) + 1).toString()}`
  }
  getStampString = currentPage => {
    const liIsCurrent =
      this.isBrowser() && window.location.href.endsWith(currentPage)
        ? "active"
        : ""
    if (!this.state.isActiveSet && liIsCurrent) {
      this.setState({ isActiveSet: true })
    }
    return `${this.stampTexture()}${liIsCurrent ? " active" : ""}`
  }

  render() {
    return (
      <div className="border-bottom-wrapper">
        <div className="nav border-bottom">
          <ul
            style={{ display: this.state.isHamburgerToggled ? null : "none" }}
          >
            <li
              className={
                this.state.isActiveSet
                  ? this.getStampString("/")
                  : `${this.stampTexture()} active`
              }
            >
              <Link activeClassName="active" to="/">
                HOME
              </Link>
            </li>
            <li className={this.getStampString("/rsvp")}>
              <Link activeClassName="active" to="/rsvp">
                RSVP
              </Link>
            </li>
            <li className={this.getStampString("/schedule")}>
              <Link activeClassName="active" to="/schedule">
                SCHEDULE
              </Link>
            </li>
            <li className={this.getStampString("/hotel")}>
              <Link activeClassName="active" to="/hotel">
                HOTEL
              </Link>
            </li>
            <li className={this.getStampString("/registry")}>
              <Link activeClassName="active" to="/registry">
                REGISTRY
              </Link>
            </li>
            <li className={this.getStampString("/faq")}>
              <Link activeClassName="active" to="/faq">
                FAQ
              </Link>
            </li>
            <li className={this.getStampString("/nyc")}>
              <Link activeClassName="active" to="/nyc">
                OUR NYC
              </Link>
            </li>
            <li className={this.getStampString("/contact")}>
              <Link activeClassName="active" to="/contact">
                CONTACT
              </Link>
            </li>
          </ul>
          <div
            className="mobile-hamburger"
            style={this.state.isHamburgerToggled ? { marginTop: "0px" } : null}
          >
            {this.state.isHamburgerToggled ? null : (
              <h3 style={{ display: "inline-block" }}>MENU</h3>
            )}
            <div className="menu-item">
              <a
                onClick={() =>
                  this.setState({
                    isHamburgerToggled: !this.state.isHamburgerToggled,
                  })
                }
              >
                {this.state.isHamburgerToggled ? (
                  <svg
                    style={this.isMobile() ? null : null}
                    width="50"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="close-icon" stroke="black" strokeWidth="10">
                      <line x1="20" y1="20" x2="80" y2="80" />
                      <line x1="20" y1="80" x2="80" y2="20" />
                    </g>
                    <rect
                      id="startButton"
                      x="0"
                      y="0"
                      width="50"
                      height="50"
                      fill="transparent"
                    />
                  </svg>
                ) : (
                  <svg
                    style={this.isMobile() ? null : null}
                    viewBox="0 0 100 80"
                    width="100"
                    height="40"
                  >
                    <rect y="20" width="100" height="10"></rect>
                    <rect y="45" width="100" height="10"></rect>
                    <rect y="70" width="100" height="10"></rect>
                  </svg>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
