import React from "react"
import Layout from "../components/layout"

const NotAttending = props => (
  <Layout>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major" style={{ textAlign: `center` }}>
            <h1>Gotcha!</h1>
            <h3 style={{ fontStyle: "italic" }}>
              we'll get back to you as soon as we can
            </h3>
          </header>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotAttending
