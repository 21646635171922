import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Clouds from "../assets/Clouds.mp4"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const isBrowser = () => typeof window !== "undefined"

  const isMobile = () => {
    if (isBrowser) {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? true
        : false
    }
    return false
  }

  return (
    <>
      <div className="body-base">
        <div className="body-container">
          {isBrowser() && !isMobile() ? (
            <video
              className="cloudVid"
              muted
              autoPlay
              loop
              preload="auto"
              src={Clouds}
              poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            />
          ) : null}
          <div className="overlay"></div>

          <Header siteTitle={data.site.siteMetadata.title} />

          <main>{children}</main>
        </div>
        <div className="full-width footer">
          <h4>
            Made with {`❤`} by <a className="inline-link" href="https://www.github.com/RxET">Roxie</a>
          </h4>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  // location: PropTypes.object.isRequired,
}

export default Layout
